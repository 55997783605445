"use strict"
import { defineComponent, ref } from 'vue';
import volumeIcon from '@/assets/home/volume-icon.png';
import volumeMute from '@/assets/home/volume-icon-muted.png';
import aboutSQVid from '@/assets/home/tempHomeVid2.mp4';
import lotteryVid from '@/assets/home/tempHomeVid.mp4';
import marbleVid from '@/assets/home/tempMarbleVid.mp4';

import LoadingScreen from '@/LoadingScreen/LoadingScreen.vue';

import './styles.css';

export default defineComponent({
    components: {
        LoadingScreen,
    },
    setup() {
        const langList = ref([
            'CN', '/', 'EN', '/', 'JP', '/', 'KR'
        ]);
        const homeNav = (target) => {
            window.location.href = target;
        };
        const isMute = ref(true);
        const volumeSrc = ref(volumeMute);
        const bgMusic = ref('');
        const toggleMusic = () => {
            isMute.value = !isMute.value;
            if (isMute.value) {
                volumeSrc.value = volumeMute;
                bgMusic.value.pause();
            } else {
                volumeSrc.value = volumeIcon;
                bgMusic.value.play();
            }
        };
        const isMobile = ref(navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        ));
        const aboutSQ_Show = ref(false);
        const openAboutSQ = () => {
            aboutSQ_Show.value = true;
        };
        const closeAboutSQ = () => {
            aboutSQ_Show.value = false;
        };
        const dialogTitles = [
            '关于赛球', '彩票', '弹子'
        ];
        const dialogTitle = ref(dialogTitles[0]);
        const dialogContents = [
            '全球线上游戏系统供应商<br />赛球成立2018年，公司是一家专注于开发多元化游戏内容的创新企业。注册资本1亿美金，总资产规模突破26亿美金，下属全资、控股企业6家 发展过程中经历了、多元化发展、创新化运营、战略化布局等3个历史阶段，自公司成立以来，我们一直致力于设计、开发和推出各类视频直播游戏产品，在线多人游戏和虚拟现实体验。我们的团队由富有创意、经验丰富的专业人才组成，努力追求游戏技术的创新和优质内容的打造。我们不仅关注游戏的娱乐性，更着眼于用户体验的提升和社交互动的创新。赛球立志成为业内的先驱，致力于将娱乐与创新相结合，为玩家带来引人入胜的游戏体验。我们提供丰富多彩的游戏选择，其中包括刺激的赛球游戏以及多元化游戏，为玩家带来高能竞技和紧张刺激的体验。公司并致力于为用户提供多样化、创新性的游戏体验，不断拓展游戏领域努力为玩家带来精彩纷呈的娱乐选择。',
            '全球线上游戏系统供应商<br />赛球成立2018年，公司是一家专注于开发多元化游戏内容的创新企业。注册资本1亿美金，总资产规模突破26亿美金，下属全资、控股企业6家 发展过程中经历了、多元化发展、创新化运营、战略化布局等3个历史阶段，自公司成立以来，我们一直致力于设计、开发和推出各类视频直播游戏产品，在线多人游戏和虚拟现实体验。我们的团队由富有创意、经验丰富的专业人才组成，努力追求游戏技术的创新和优质内容的打造。我们不仅关注游戏的娱乐性，更着眼于用户体验的提升和社交互动的创新。赛球立志成为业内的先驱，致力于将娱乐与创新相结合，为玩家带来引人入胜的游戏体验。我们提供丰富多彩的游戏选择，其中包括刺激的赛球游戏以及多元化游戏，为玩家带来高能竞技和紧张刺激的体验。公司并致力于为用户提供多样化、创新性的游戏体验，不断拓展游戏领域努力为玩家带来精彩纷呈的娱乐选择。',
            '全球线上游戏系统供应商<br />赛球成立2018年，公司是一家专注于开发多元化游戏内容的创新企业。注册资本1亿美金，总资产规模突破26亿美金，下属全资、控股企业6家 发展过程中经历了、多元化发展、创新化运营、战略化布局等3个历史阶段，自公司成立以来，我们一直致力于设计、开发和推出各类视频直播游戏产品，在线多人游戏和虚拟现实体验。我们的团队由富有创意、经验丰富的专业人才组成，努力追求游戏技术的创新和优质内容的打造。我们不仅关注游戏的娱乐性，更着眼于用户体验的提升和社交互动的创新。赛球立志成为业内的先驱，致力于将娱乐与创新相结合，为玩家带来引人入胜的游戏体验。我们提供丰富多彩的游戏选择，其中包括刺激的赛球游戏以及多元化游戏，为玩家带来高能竞技和紧张刺激的体验。公司并致力于为用户提供多样化、创新性的游戏体验，不断拓展游戏领域努力为玩家带来精彩纷呈的娱乐选择。',
        ];
        const dialogContent = ref(dialogContents[0]);
        const dialogVideoPlayer = ref('');
        const dialogVids = [
            aboutSQVid,
            lotteryVid,
            marbleVid,
        ];
        const selDiaId = ref(0);
        const selectDialog = (id) => {
            dialogTitle.value = dialogTitles[id];
            dialogContent.value = dialogContents[id];
            dialogVideoPlayer.value.src = dialogVids[id];
            selDiaId.value = id;
        };
        const playGame = (game) => {
            if (game === 'Lottery') {
                window.location.href = 'https://sqkj1.com/#/?lotteryId=3';
            } else if (game === 'Marble') {
                window.location.href = 'https://sqkj1.com/#/?lotteryId=4';
            }
        }
        const homeVidLoaded = ref(false);
        const handleVidLoaded = () => {
            setTimeout(() => {
                homeVidLoaded.value = true;
            }, 2000);
        };
        return {
            homeNav,
            langList,
            isMute,
            toggleMusic,
            volumeSrc,
            bgMusic,
            isMobile,
            aboutSQ_Show,
            openAboutSQ,
            closeAboutSQ,
            dialogTitle,
            dialogContent,
            dialogVideoPlayer,
            selDiaId,
            selectDialog,
            playGame,
            homeVidLoaded,
            handleVidLoaded,
        }
    }
});