<template>
    <LoadingScreen :loadingComplete="homeVidLoaded" />
    <video class="homeVid" autoplay loop muted playsinline @loadeddata="handleVidLoaded">
        <source src="@/assets/home/background (1).mp4" type="video/mp4" />
    </video>
    <div class="topLeft-btn-group">
        <img src="@/assets/home/SaiQ_Logo.png" />
    </div>
    <div v-if="!isMobile" class="topRight-btn-group">
        <div>
            <img src="@/assets/home/SaiQ_white.png" />
            <span>Sai Qiu</span>
        </div>
        <div class="language-btns-group">
            <span v-for="(lang, index) in langList" :key="index">
                {{lang}}
            </span>
        </div>
    </div>
    <div class="loadingEffects-container">
        <img class="loadingEffects" src="@/assets/home/LoadingEffect.png" alt="" />
    </div>
    <div :style="aboutSQ_Show ? 'opacity: 1' : 'opacity: 0'" :class="aboutSQ_Show ? 'aboutSQ_wrapper fade-in show' : 'aboutSQ_wrapper fade-in'">
        <img class="mob-topLeft-img" src="@/assets/home/tempTopLeft.png" />
        <img v-show="isMobile" class="mob-btm-wrapper" src="@/assets/home/bottomFrontImg.png" />
        <div class="aboutSQ-dialog">
            <div class="content-wrapper">
                <el-header class="dialog-title-container"><div class="dialog-title">{{dialogTitle}}</div></el-header>
                <el-main class="dialog-main-content">
                    <el-row class="dialog-row-showcase">
                        <video ref="dialogVideoPlayer" class="dialog-showcase" autoplay loop muted playsinline>
                            <source src="@/assets/home/tempHomeVid2.mp4" type="video/mp4" />
                        </video>
                    </el-row>
                    <el-row class="dialog-row-text">
                        <p class="dialog-text" v-html="dialogContent"></p>
                    </el-row>
                </el-main>
                <el-row class="dialog-text-empty-space"></el-row>
            </div>
            <el-footer class="dialog-footer">
                <el-row class="dialog-nav">
                    <el-button @click="selectDialog(0)" :class="selDiaId === 0 ? 'dialog-nav-btn active' : 'dialog-nav-btn'">关于赛球</el-button>
                </el-row>
                <el-row class="dialog-nav">
                    <div class="vertical-rule"></div>
                </el-row>
                <el-row class="dialog-nav">
                    <el-button @click="selectDialog(1)" :class="selDiaId === 1 ? 'dialog-nav-btn active' : 'dialog-nav-btn'">彩票</el-button>
                </el-row>
                <el-row class="dialog-nav">
                    <div class="vertical-rule"></div>
                </el-row>
                <el-row class="dialog-nav">
                    <el-button @click="selectDialog(2)" :class="selDiaId === 2 ? 'dialog-nav-btn active' : 'dialog-nav-btn'">弹子</el-button>
                </el-row>
                <el-row class="dialog-options">
                    <img @click="closeAboutSQ" class="close-dialog-btn" src="@/assets/home/close-icon.png" />
                </el-row>
            </el-footer>
        </div>
    </div>
    <div v-if="!isMobile" v-show="!aboutSQ_Show" class="bottomCenter-btns-group">
        <div class="btns-group-container">
            <div @click="openAboutSQ" class="btn-container">
                <div class="btn-home-nav"></div>
                <span class="btn-home-text">关于赛球</span>
            </div>
            <div class="btn-divider"></div>
            <div @click="homeNav('https://sqkj1.com/#/?lotteryId=3')" class="btn-container">
                <div class="btn-home-nav"></div>
                <span class="btn-home-text">彩票</span>
            </div>
            <div class="btn-divider"></div>
            <div @click="homeNav('https://sqkj1.com/#/?lotteryId=4')" class="btn-container">
                <div class="btn-home-nav"></div>
                <span class="btn-home-text">弹子</span>
            </div>
        </div>
    </div>
    <div v-show="!aboutSQ_Show" v-else class="mob-bottomCenter-btns-group">
        <div @click="openAboutSQ" class="enter-mobile-menu"><span class="openSQText">Enter</span></div>
    </div>
    <div class="bottomRight-btns-group">
        <audio ref="bgMusic" loop hidden>
            <source src="@/assets/home/timelines.mp3" type="audio/mp3" />
        </audio>
        <img @click="toggleMusic" class="bg-music-toggle" :src="volumeSrc" :style="aboutSQ_Show ? 'box-shadow: 0 0 5px 5px #de553c;' : null" />
    </div>
    <div v-show="isMobile && !aboutSQ_Show" class="mob-topRight-btn-group flex flex-wrap items-center">
        <el-dropdown class="mob-dropdown">
            <div class="dropdown-btn" type="light">
                <img class="burger-icon" src="@/assets/home/BurgerIcon.png" />
            </div>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="playGame('Lottery')">彩票</el-dropdown-item>
                    <el-dropdown-item @click="playGame('Marble')">弹子</el-dropdown-item>
                    <!-- Language Options will be integrated here someday... -->
                    <!--<el-dropdown-item>Language</el-dropdown-item>-->
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
    <div v-show="isMobile && aboutSQ_Show" class="new-mob-topRight-btn-group">
        <el-dropdown class="mob-dropdown">
            <div class="dropdown-btn" type="light">
                <svg class="btn-bg" xmlns="http://www.w3.org/2000/svg" width="40px" viewBox="0 0 125 125" fill="none">
                    <circle cx="62.2502" cy="62.7034" r="59.2502" fill="white" stroke="#E70400" stroke-width="6" />
                </svg>
                <svg class="btn-burger" xmlns="http://www.w3.org/2000/svg" width="24" height="54" viewBox="0 0 73 54" fill="none">
                    <rect x="0.0859375" y="0.179688" width="72.3352" height="8.03724" rx="4.01862" fill="#E70400" />
                    <rect x="0.0859375" y="22.686" width="72.3352" height="8.03724" rx="4.01862" fill="#E70400" />
                    <rect x="0.0859375" y="45.1885" width="72.3352" height="8.03724" rx="4.01862" fill="#E70400" />
                </svg>
            </div>
            <template #dropdown>
                <el-dropdown-menu>
                    <el-dropdown-item @click="playGame('Lottery')">彩票</el-dropdown-item>
                    <el-dropdown-item @click="playGame('Marble')">弹子</el-dropdown-item>
                    <!-- Language Options will be integrated here someday... -->
                    <!--<el-dropdown-item>Language</el-dropdown-item>-->
                </el-dropdown-menu>
            </template>
        </el-dropdown>
    </div>
</template>
<script src="./App.js"></script>
<style lang="scss" scoped>
.homeVid{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
}
.topLeft-btn-group{
    display: none;
/*    temporary*/
    position: fixed;
    top: 2rem;
    left: 1.5rem;
    pointer-events: visible;
    z-index: 1;
    img{
        width: 6vh;
    }
}
.topRight-btn-group{
    position: fixed;
    top: 2rem;
    right: 2rem;
    color: white;
    display: flex;
    justify-content: center;
    align-content: center;
    font-size: 16px;
    div{
        display: flex;
        justify-content: center;
        align-content: center;
        padding: 8px;
        img{
            width: 40px;
            height: 40px;
            margin: auto 4px;
        }
        span{
            color: white;
            margin: auto;
        }
    }
    .language-btns-group{
        display: flex;
        justify-content: center;
        align-content: center;
        span{
            margin: auto;
            padding: 6px;
        }
    }
}
.mob-topRight-btn-group{
    position: fixed;
    top: 2rem;
    right: 2rem;
    color: white;
    .mob-dropdown{
        .dropdown-btn{
            .burger-icon{
                width: 40px;
                height: 40px;
            }
        }
    }
}
.new-mob-topRight-btn-group{
    position: fixed;
    top: 2rem;
    right: 2rem;
    .btn-burger{
        position: absolute;
        top: -16%;
        left: 20%;
    }
}
.bottomCenter-btns-group {
    position: fixed;
    bottom: 5rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    .btns-group-container{
        display: flex;
        justify-content: center;
        align-content: center;
        .btn-container{
            width: 48px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            .btn-home-nav{
                background-color: white;
                border-radius: 50%;
                border: none;
                height: 20px;
                width: 20px;
                box-shadow: 0 0 2px 2px #888888;
                cursor: pointer;
            }
            span{
                width: 64px;
                font-size: 16px;
                color: white;
            }
        }
        .btn-divider{
            height: 2px;
            width: 40px;
            background-color: white;
            border-radius: 4px;
            margin-top: 10px;
        }
    }
}
.mob-bottomCenter-btns-group{
    position: fixed;
    bottom: 5rem;
    width: 100%;
    opacity: 0.8;
    display: flex;
    align-content: center;
    justify-content: center;
    .enter-mobile-menu{
        width: 60px;
        height: 60px;
        border: none;
        border-radius: 50%;
        background-color: white;
        font-size: 20px;
        font-family: Roboto;
        box-shadow: 0 0 10px 5px white;
        display: flex;
        align-content: center;
        justify-content: center;
        .openSQText{
            line-height: 60px;
        }
    }
}
.loadingEffects{
    position: fixed;
    top: 10vh;
    left: 50vw;
    transform: translate(-50%,0);
    width: 100vw;
    max-width: 500px;
    margin: 0 auto;
    height: 90vh;
}
.fade-in{
    transform: scale(0.3);
    opacity: 0;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-in-out;
}
.fade-in.show {
    transform: scale(1);
    opacity: 1;
}
.aboutSQ_wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: white;
    background-image: url('@/assets/home/newBGfromSVG.png');
    background-size: 492px 1009px;
    .mob-topLeft-img{
        position: fixed;
        top: 0;
        left: 0;
        width: 80px;
        height: 80px;
    }
    .mob-btm-wrapper{
        display: block;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        max-height: 200px;
        pointer-events: none;
        -webkit-mask-image: linear-gradient(to bottom, transparent 20%, black 100%);
        mask-image: linear-gradient(to bottom, transparent 20%, black 100%);
    }
    .aboutSQ-dialog{
        margin: 10vh auto 0 auto;
        width: 90%;
        max-width: 1200px;
        display: flex;
        flex-direction: column;
        .content-wrapper{
            background: linear-gradient(180deg, #de553c 0%, rgba(255, 223, 107, 0.00) 100%);
            border-radius: 20px;
            border-bottom: 2px solid #cc9245;
            .dialog-title-container{
                .dialog-title{
                    background-image: url('assets/home/dialogTitleBgImg.png');
                    background-repeat: no-repeat;
                    background-size: 200px 48px;
                    background-position: center;
                    height: 48px;

                    line-height: 52px;
                    text-align: center;

                    font-family: PingFang SC;
                    font-size: 24px;

                    color: white;
                }
            }
            .dialog-main-content{
                padding-top: 0;
                text-align: center;
                max-height: 53vh;
                height: calc(var(--vh, 1vh) * 65);
                .dialog-row-showcase{
                    display: flex;
                    justify-content: center;
                    .dialog-showcase{
                        width: 100%;
                        max-width: 600px;
                        border-radius: 10px;
                    }
                }
                .dialog-row-text{
                    margin-top: 20px;
                    .dialog-text{
                        font-size: 16px;
                        color: black;
                    }
                }
            }
            .dialog-text-empty-space{
                width: 100%;
                height: 20px;
            }
        }
        .dialog-footer{
            padding-top: 8px;
            padding-bottom: 8px;
            height: 100%;
            .dialog-nav{
                justify-content: center;
                .dialog-nav-btn{
                    margin-top: 4px;
                    border: none;
                    width: 100px;
                    background-color: transparent;
                    color: black;
                    opacity: 0.6;
                    font-size: 18px;
                    &.active{
    /*                    background-color: #00D2AC;*/
                        background: linear-gradient(to top, #de593c, #fcbe87);
                        border-radius: 30px;
                        color: white;
                        opacity: 1;
                    }
                }
            }
            .dialog-options{
                .close-dialog-btn{
                    position: fixed;
                    bottom: 2rem;
                    left: 2rem;
                    height: 24px;
                    width: 24px;
                    margin-top: 8px;
                }
            }
        }
    }
}
.bottomRight-btns-group{
    position: fixed;
    bottom: 2rem;
    right: 2rem;
    .bg-music-toggle{
        width: 5vh;
        border-radius: 50%;
    }
}
::-webkit-scrollbar{
    width: 5px;
}
::-webkit-scrollbar-track{
    background: #f1f1f1;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb{
    background: #888;
    border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover{
    background: #555;
}
</style>
