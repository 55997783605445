<template>
    <div v-if="!loadingComplete" class="loadingContainer">
        <img class="sqLogo" src="./SQLogoLoading.png" alt="SQLoadingLogo"/>
    </div>
</template>
<script src="./LoadingScreen.js"></script>
<style lang="scss" scoped>
.loadingContainer{
    background-image: url('./loading.jpg');
    background-size: cover;
    background-size: no-repeat;
    background-position: center;
    height: 100vh;
    width: 100vw;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    .sqLogo{
        width: 150px;
        height: 150px;
        z-index: 1001;
    }
}
</style>